.grid-item {
    /* Your grid item styles here */
  
    /* Add padding for all screen sizes */


    /* Media query for screens with a maximum width of 768px (typical for mobile phones) */
    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .root {
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 10%;
    background:#102372 !important;
  }

  .paper{

    padding: 20;
    text-align: 'center';
  }