.register-input {
    margin-top: 5px;
    margin-bottom: 5px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    color: #FCBD2A !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: #c29f4d !important;
}

.css-117w1su-MuiStepIcon-text{
    font-weight: bold;
}