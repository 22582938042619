.custom-card:hover {

    transition: transform 0.3s;
    transform: scale(1.01);
}

.title-bar {
    color: white !important;
    background-color: #000829;
background-image: url("https://www.transparenttextures.com/patterns/diagmonds-light.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}